#root {
  height: 100vh;
  background-color: #fff;
}

*,
html{
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.ant-carousel{
  width: 100%;
}
